import { Product } from '@/data/product/types'
import { HttpService } from '@/util/http'

export default class {
  constructor(private readonly httpService: HttpService) {}

  async getProducts(): Promise<Product[]> {
    const res = await this.httpService.get<null, Product[]>('display/prices')
    return res.data
  }
}
