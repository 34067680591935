import { createApp } from 'vue'
import App from './App.vue'
import globals from '@/plugins/globals'
import BigNumber from 'bignumber.js'
import './assets/tailwind.css'

const fmt = {
  decimalSeparator: ',',
  groupSeparator: '.',
}

BigNumber.config({ FORMAT: fmt })

const app = createApp(App)
app.use(globals)
app.mount('#app')
