export interface HttpErrorData {
  title: string
  detail?: string
  status?: number
  type?: string
  code?: string
  meta?: Record<string, unknown>
}

export enum AppState {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  FETCHING = 'FETCHING',
  ERROR = 'ERROR',
}
