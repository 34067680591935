
import { defineComponent, PropType } from 'vue'
import { ProductVariation } from '@/data/product/types'
import BigNumber from 'bignumber.js'

export default defineComponent({
  props: {
    variation: {
      type: Object as PropType<ProductVariation>,
      required: true,
    },
  },
  methods: {
    formatCurrency(value: string) {
      return new BigNumber(value).toFormat(2)
    },
  },
})
