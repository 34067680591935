import { Product } from '@/data/product/types'

const PRODUCTS_KEY = 'products'

export default {
  getProducts(): Product[] {
    try {
      const productsStr = localStorage.getItem(PRODUCTS_KEY) || ''
      return JSON.parse(productsStr) as Product[]
    } catch (e) {
      return []
    }
  },
  setProducts(products: Product[]): void {
    try {
      const productsStr = JSON.stringify(products)
      localStorage.setItem(PRODUCTS_KEY, productsStr)
    } catch (e) {
      // continue regardless of error
    }
  },
}
