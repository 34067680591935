
import { defineComponent, inject } from 'vue'

import ProductCache from '@/data/product/cache'
import { AppState } from '@/data/types'
import { Product } from '@/data/product/types'
import { ProductRepositoryKey } from '@/data/injectables'
import { wait } from '@/util/lib'

import ErrorView from '@/components/ErrorView.vue'
import FetchingView from '@/components/FetchingView.vue'
import ProductsView from '@/components/ProductsView.vue'
import AppStateTag from '@/components/AppStateTag.vue'

const REFRESH_INTERVAL = Number(process.env.VUE_APP_REFRESH_INTERVAL || 30000)

const FETCH_OFFSET_IN_MS = 2000

export default defineComponent({
  components: { ErrorView, FetchingView, ProductsView, AppStateTag },
  setup: () => {
    const productRepository = inject(ProductRepositoryKey)
    return { productRepository }
  },
  data() {
    return {
      keepRefreshing: true,
      products: [] as Product[],
      secondsBeforeRefresh: 0,
      updateIntervalInSeconds: 0,
      state: 'FETCHING' as keyof typeof AppState,
    }
  },
  created() {
    this.refreshView()
  },
  methods: {
    async refreshView() {
      do {
        try {
          const products = (await this.productRepository?.getProducts()) || []
          ProductCache.setProducts(products)
          this.products = products
          this.setState(AppState.ONLINE)
          await wait(REFRESH_INTERVAL)
        } catch (e) {
          if (this.products.length) {
            this.setState(AppState.OFFLINE)
            await wait(REFRESH_INTERVAL)
          } else {
            const productsCache = ProductCache.getProducts()
            if (productsCache.length) {
              this.products = productsCache
              this.setState(AppState.OFFLINE)
              await wait(REFRESH_INTERVAL)
            } else {
              this.setState(AppState.ERROR)
              await this.waitRefreshCooldown()
              this.setState(AppState.FETCHING)
              await wait(FETCH_OFFSET_IN_MS)
            }
          }
        }
      } while (this.keepRefreshing)
    },
    waitRefreshCooldown(): Promise<void> {
      return new Promise((resolve) => {
        this.secondsBeforeRefresh = REFRESH_INTERVAL / 1000
        const updateSecondsInterval = setInterval(() => {
          this.secondsBeforeRefresh--
          if (this.secondsBeforeRefresh === 0) {
            clearInterval(updateSecondsInterval)
            resolve()
          }
        }, 1000)
      })
    },
    setState(state: AppState) {
      this.state = state
    },
  },
})
