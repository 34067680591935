import { httpService } from '@/util/http'
import ProductRepository from '@/data/product/repository'

import { HttpServiceKey, ProductRepositoryKey } from '@/data/injectables'

import { App } from 'vue'

export default {
  install: (app: App<Element>): void => {
    app.provide(HttpServiceKey, httpService)
    app.provide(ProductRepositoryKey, new ProductRepository(httpService))

    app.config.globalProperties.$filters = {
      date(date: Date, locale = 'pt-BR') {
        return date.toLocaleDateString(locale)
      },
    }
  },
}
