
import { defineComponent, PropType } from 'vue'

import View from '@/components/View.vue'

export default defineComponent({
  components: { View },
  props: {
    secondsBeforeRefresh: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  computed: {
    secondaryText() {
      return this.secondsBeforeRefresh > 0
        ? `Tentando novamente em ${this.secondsBeforeRefresh} segundos.`
        : 'Tente novamente em alguns minutos.'
    },
  },
})
